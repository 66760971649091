/*CSS for all filters changes*/

.black {
  color: black;
}

.bg-transparent {
  background-color: transparent;
}


.cursor {
  cursor: url("assets/img/cursor.png"), auto;
}


.dislex{
  font-family: "OpenDyslexic Regular";
}

.sizetext {
  font-size: 160%;
  line-height: 1.3;
}



/*Website fonts*/

@font-face {
  font-family: "OpenDyslexic Regular";
  src: url(assets/fonts/OpenDyslexic-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Gill Sans Heavy";
  src: url(assets/fonts/GillSansHeavy.otf) format("opentype");
}

@font-face {
  font-family: "Gill Sans Bold";
  src: url(assets/fonts/GillSansBold.otf) format("opentype");
}

@font-face {
  font-family: "Gill Sans Medium";
  src: url(assets/fonts/GillSansMedium.otf) format("opentype");
}


@font-face {
  font-family: "Gill Sans Light";
  src: url(assets/fonts/GillSansLight.otf) format("opentype");
}

.roboto {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}


/*Eliminate columns paddings and margins*/

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}


/*rounded boxes*/


.corners-rounded {
  border-radius: 18px 18px 18px 18px;
  -moz-border-radius: 18px 18px 18px 18px;
  -webkit-border-radius: 18px 18px 18px 18px;
}

.shadow-box {
  -webkit-box-shadow: 4px 7px 41px -1px rgba(0,0,0,0.14);
  -moz-box-shadow: 4px 7px 41px -1px rgba(0,0,0,0.14);
  box-shadow: 4px 7px 41px -1px rgba(0,0,0,0.14);
}


/*Header background of all pages */

.icon-cover-page {
  width: 13%;
  margin-top: 8%;
}

/*-------->!*Font weight class *!*/

.heavy-f {
  font-family: "Gill Sans Heavy";
}

.bold-f {
  font-family: "Gill Sans Bold";
}

.medium-f {
  font-family: "Gill Sans Medium";
}

.light-f {
  font-family: "Gill Sans light";
}


/*-------->!*Font size class *!*/

.access {
  font-size: 90%;
}

.nav-text {
  font-size: 83%;
}

.extrahuge-t {
  font-size: 41pt;
}


.huge-t {
  font-size: 32pt;
}

.big-t {
  font-size: 17pt;
}

.anteojos-t {
  font-size: 20pt;
}

.bigger-t {
  font-size: 25pt;
}


.text-big {
  font-size: 140%;
}

.text-normal {
  font-size: 120%;
  line-height: 1.3;
}

.text-small {
  font-size: 100%;
}

.title-line-height {
  line-height: 1.4;
}

/*-------->!*Colors class *!*/

.gradient {
  background: rgb(17,182,186);
  background: linear-gradient(142deg, rgba(17,182,186,1) 0%, rgba(6,120,142,1) 61%);
}

.gradient-2 {
  background: rgb(17,182,186);
  background: linear-gradient(142deg, rgba(17,182,186,1) 6%, rgba(12,154,166,1) 44%, rgba(4,115,130,1) 100%);
}

.gradient-3 {
  background: rgb(40,188,191);
  background: linear-gradient(141deg, rgba(40,188,191,1) 27%, rgba(30,210,214,1) 100%);
}


.turquesa {
color: #06788E;
}

.blue {
  color: #10A4B0;
}

.green {
color: #00a44f;
}

.yellow {
color: #efa423;
}

.orange {
  color: #f09022;
}

.gray {
  color: dimgray;
}

.white {
  color: white;
}

.bg-white {
  background-color: white;
}

.bg-green {
background-color: #00a44f;
}

.bg-yellow {
background-color: #efa423;
}

.bg-gray {
  background-color: rgb(245,246,247);
}

.bg-turquesa {
  background-color: #06788E;
}

.bg-blue {
  background-color: #10A4B0;
}

.dark{
  color: #333333;
}

.only-phone {
  display: none !important;
}

.only-ipad {
  display: none;
}

.none-ipad {
  display: block;
}

.none-desktop {
  display: none;
}


@media screen and (max-width: 1030px) {

  .icon-cover-page {
    width: 20%;
    margin-top: 10%;
  }

  .none-ipad {
    display: none;
  }

  .only-phone {
    display: none !important;
  }

  .only-ipad {
    display: block;
  }

  .nav-text {
    font-size: 107%;
  }

  .none-desktop {
    display: block;
  }

}

@media screen and (max-width: 825px) {


  .icon-cover-page {
    width: 25%;
    margin-top: 10%;
  }

  .only-phone {
    display: none !important;
  }

  .only-ipad {
    display: block;
  }

  .none-desktop {
    display: block;
  }

  .none-ipad {
    display: none;
  }

  .nav-text {
    font-size: 105%;
  }

  .extrahuge-t {
    font-size: 200%;
  }

  .huge-t {
    font-size: 180%;
  }

  .big-t {
    font-size: 120%;
  }

  .bigger-t {
    font-size: 160%;
  }

  .anteojos-t {
    font-size: 140%;
  }
}


@media screen and (max-width: 600px) {

  .icon-cover-page {
    width: 50%;
    margin-top: 25%;
  }

  .nav-text {
    font-size: 90%;
  }

  .none-desktop {
    display: block;
  }

  .huge-t {
    font-size: 150%;
  }

  .big-t {
    font-size: 120%;
  }

  .anteojos-t {
    font-size: 120%;
  }

  .bigger-t {
    font-size: 150%;
  }

  .only-phone {
    display: block !important;
  }

  .only-ipad {
    display: none;
  }

  .none-phone {
    display: none !important;
  }


}


html, body {

}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.grecaptcha-badge {
  visibility: hidden;
}
